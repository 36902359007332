.Orb
{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.wrapper{
	overflow: hidden;
	height: 500px;
	width: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.loading::before{
	width: 100%;
	height: 100%;
	content: " ";
	border-radius: 50%;
	box-shadow: 0 0 20px 12px rgba(91, 87, 94, 0.35);
	position: absolute;
  top: 0px;
  left: 0px;
}

.loading{
	color: #000;
	background: linear-gradient(0deg, #959296, white, #68666a);
	width: 40vh;
	height: 40vh;
	border-radius: 50%;
	position: relative;
	animation: rotate_animation 4s ease-in-out infinite alternate, pulse_animation 3s ease-out infinite;
}

.loading::after{
	width: calc(40vh - 6px);
	height: calc(40vh - 6px);
	background-color: currentColor;
	content: " ";
	position: absolute;
	top: 3px;
	left: 3px;
	border-radius: 50%;
	animation: pulse_animation 3s ease-out infinite;
	animation-delay: .2s;
}

@keyframes rotate_animation {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(600deg); }
}

@keyframes pulse_animation {
	0%, 40% { box-shadow: 0 0 5px 0px rgba(86, 85, 87, 0.2); }
	100% { box-shadow: 0 0 5px 80px rgba(0, 0, 255, 0); }
}