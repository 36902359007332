.Home {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: black;
  color: white;
  flex-grow: 1;
  height: 100%;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}